import { makeAutoObservable, observable, runInAction } from 'mobx'

import { API } from 'api/api'
import type { CoreAPIErrorResponse } from 'api/errors'
import {
  combineAccountLists,
  getGoalMetricsFromResponse,
  getMachineLearningMetricsFromResponse,
  mapApiResponseToStore,
} from 'store/reporting/reporting.store.utils'
import type { ChildStore } from 'store/StoreTypes'

import type { MotionIdentifiers } from 'models/motion.model'
import type {
  DataTimeFrame,
  OperationalStatisticsPayload,
  MetricsPayload,
  Reporting,
  ReportingLoading,
  AccountList,
  MachineLearningMetrics,
} from 'models/reporting.model'

export const DEFAULT_PAGE_SIZE = 10

export class ReportingStore implements ChildStore {
  loading: ReportingLoading = {
    isMotionLoading: false,
    isOperationalStatisticsLoading: false,
    isGoalMetricsLoading: false,
    isMachineLearningMetricsLoading: false,
    isAccountListLoading: false,
    isEmailStatsLoading: false,
  }
  data: Reporting = {
    accountList: {
      columns: [],
      data: [],
      total: 0,
    } as AccountList,
    machineLearningMetrics: {
      data: [],
      labels: [],
      title: '',
    } as MachineLearningMetrics,
    operationalStatistics: {},
    motionOperationalStatistics: {},
    emailStatistics: [],
  }
  dataTimeFrame: DataTimeFrame = { goalMetricsTimeFrame: 10, machineLearningMetricsTimeFrame: 10 }

  apiError: CoreAPIErrorResponse | null = null

  constructor() {
    makeAutoObservable(this, {
      loading: observable,
    })
  }

  reset = () => {
    this.loading = {
      isMotionLoading: false,
      isOperationalStatisticsLoading: false,
      isGoalMetricsLoading: false,
      isMachineLearningMetricsLoading: false,
      isAccountListLoading: false,
      isEmailStatsLoading: false,
    }
    this.data = {
      accountList: {
        columns: [],
        data: [],
        total: 0,
      } as AccountList,
      machineLearningMetrics: {
        data: [],
        labels: [],
        title: '',
      } as MachineLearningMetrics,
      operationalStatistics: {},
      motionOperationalStatistics: {},
      emailStatistics: [],
    }
    this.dataTimeFrame = { goalMetricsTimeFrame: 10, machineLearningMetricsTimeFrame: 10 }
    this.apiError = null
  }

  getMotion = async ({ playbookId, version }: MotionIdentifiers) => {
    this.loading.isMotionLoading = true
    try {
      const motion = await API.reporting.getReportingMotion({ playbookId, version })
      runInAction(() => {
        this.data.motion = motion
      })
      return motion
    } catch (error: unknown) {
      this.setApiError(error as CoreAPIErrorResponse)
    } finally {
      runInAction(() => {
        this.loading.isMotionLoading = false
      })
    }
  }

  getOperationalStatistics = async ({ journeyId, timestamp }: OperationalStatisticsPayload): Promise<void> => {
    this.loading.isOperationalStatisticsLoading = true
    try {
      const operationalStatistics = await API.reporting.getOperationalStatistics({ journeyId, timestamp })
      runInAction(() => {
        this.data.operationalStatistics = operationalStatistics
      })
    } catch (error: unknown) {
      this.setApiError(error as CoreAPIErrorResponse)
    } finally {
      runInAction(() => {
        this.loading.isOperationalStatisticsLoading = false
      })
    }
  }

  getGoalMetrics = async ({ journeyId, weeks }: MetricsPayload): Promise<void> => {
    this.loading.isGoalMetricsLoading = true
    this.dataTimeFrame.goalMetricsTimeFrame = weeks
    try {
      const goalMetricsResponse = await API.reporting.getGoalMetrics({ journeyId, weeks })
      const goalMetrics = getGoalMetricsFromResponse(goalMetricsResponse)
      runInAction(() => {
        this.data.goalMetrics = goalMetrics
      })
    } catch (error: unknown) {
      this.setApiError(error as CoreAPIErrorResponse)
    } finally {
      runInAction(() => {
        this.loading.isGoalMetricsLoading = false
      })
    }
  }

  getMachineLearningMetrics = async ({ journeyId, weeks }: MetricsPayload): Promise<void> => {
    this.loading.isMachineLearningMetricsLoading = true
    this.dataTimeFrame.machineLearningMetricsTimeFrame = weeks
    try {
      const apiResponse = await API.reporting.machineLearningMetrics({ journeyId, weeks })
      const machineLearningMetrics = getMachineLearningMetricsFromResponse(apiResponse)
      runInAction(() => {
        this.data.machineLearningMetrics = machineLearningMetrics
      })
    } catch (error: unknown) {
      this.setApiError(error as CoreAPIErrorResponse)
    } finally {
      runInAction(() => {
        this.loading.isMachineLearningMetricsLoading = false
      })
    }
  }

  /**
   * Get the account list for the given Motion ID.
   * @param {string} journeyId The Motion ID
   * @param {number} [limit=10] The number of items to return
   * @param {number} [offset=0] The offset to start from
   * @param {string} [search=''] The search string
   * @param {boolean} [reset=false] When true, reset the data, when false append the data.
   */
  getAccountList = async ({
    journeyId,
    version,
    limit = 10,
    offset = 0,
    search = '',
    reset = false,
    dimension,
  }: {
    journeyId: string
    version: string
    limit?: number
    offset?: number
    search?: string
    reset?: boolean
    dimension: string
  }): Promise<void> => {
    this.loading.isAccountListLoading = true
    try {
      const accountListApiResponse = await API.reporting.getAccountList(journeyId, limit, offset, search)

      const participatedMotions = await API.insights.getParticipatedMotionsByMotionId({
        id: journeyId,
        version,
      })
      const accountInsights = await API.insights.getTenantAccountsInsights({ dimension })
      const combinedAccountLists = combineAccountLists(accountListApiResponse, accountInsights, participatedMotions)

      runInAction(() => {
        this.data.accountList = mapApiResponseToStore(
          combinedAccountLists,
          limit,
          offset,
          reset ? { columns: [], data: [], total: 0 } : this.data.accountList,
        )
      })
    } catch (error: unknown) {
      this.setApiError(error as CoreAPIErrorResponse)
    } finally {
      runInAction(() => {
        this.loading.isAccountListLoading = false
      })
    }
  }

  /**
   * Get the email stats for the given Motion ID.
   */
  getEmailStats = async ({ journeyId }: { journeyId: string }): Promise<void> => {
    this.loading.isEmailStatsLoading = true
    try {
      const emailStatistics = await API.reporting.getEmailStats({ journeyId })
      runInAction(() => {
        this.data.emailStatistics = emailStatistics
      })
    } catch (error: unknown) {
      this.setApiError(error as CoreAPIErrorResponse)
    } finally {
      runInAction(() => {
        this.loading.isEmailStatsLoading = false
      })
    }
  }

  get isMotionEmpty(): boolean {
    return !Object.keys(this.data.motion ?? {}).length
  }

  get isOperationalStatisticsEmpty(): boolean {
    return !this.data.operationalStatistics || Object.keys(this.data.operationalStatistics).length === 0
  }

  get isGoalMetricsEmpty(): boolean {
    return (
      !this.data.goalMetrics ||
      !this.data.goalMetrics?.length ||
      this.data.goalMetrics.every((metric) => !Object.keys(metric?.data).length)
    )
  }

  get isMachineLearningMetricsEmpty(): boolean {
    return !this.data.machineLearningMetrics || !this.data.machineLearningMetrics.data.length
  }

  get isAccountListEmpty(): boolean {
    return !this.data.accountList || !this.data.accountList.data.length
  }

  get isEmailStatsEmpty(): boolean {
    return !this.data.emailStatistics || !this.data.emailStatistics.length
  }

  setApiError = (error: CoreAPIErrorResponse | null) => {
    runInAction(() => {
      this.apiError = error
    })
  }
}
