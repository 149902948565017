export interface TopActions {
  name: string
  total: number
  platform: string
}

interface TenantActionTotalsGraphDataPoints {
  [key: string]: {
    total: number
    date: number
  }[]
}

export interface TenantActionTotalsIntegration {
  total: number
  percentageTotal: string
  actions: {
    [key: string]: {
      total: number
      percentageTotal: string
    }
  }
}

export interface TenantActionTotalsIntegrations {
  [key: string]: TenantActionTotalsIntegration
}

export interface TenantActionTotalsStats {
  actions: number
  users: number
  accounts: number
}

export interface TenantActionTotals {
  totals: TenantActionTotalsStats
  previousTotals?: TenantActionTotalsStats
  integrations: TenantActionTotalsIntegrations
  graphDataPoints?: TenantActionTotalsGraphDataPoints
}

export interface DateRangeProps {
  dateFrom: number
  dateTo: number
}

interface GraphDataPoint {
  total: number
  date: number
}

export interface ParticipantTotals {
  ENTERED: number
  ACTIVE: number
  COMPLETED: number
}

export enum ParticipantTotalsEnum {
  Completed = 'COMPLETED',
  Active = 'ACTIVE',
  Entered = 'ENTERED',
}

export interface TenantTotals {
  participantTotals: ParticipantTotals
  graphDataPoints: {
    daily: GraphDataPoint[]
    weekly: GraphDataPoint[]
    quarterly: GraphDataPoint[]
    yearly: GraphDataPoint[]
  }
}

export interface TopMotion {
  journeyId: string
  journeyName: string
  totals: ParticipantTotals
}

export interface TenantMotionTotals {
  tenantTotals: TenantTotals
  topJourneys: TopMotion[]
}

export interface TenantInMotionReportingTotals {
  nodes: TenantInMotionReportingTotalsNode[]
}

export interface TenantInMotionReportingTotalsNode {
  id: string
  total: number
  statusTotals: TenantInMotionReportingTotalsStatusTotal[]
}

export interface TenantInMotionReportingTotalsStatusTotal {
  status: string
  total: number
  percentageTotal: string
}

export interface SegmentReportingItem {
  total: number
  percentageTotal: string
  segmentColor: string
}

export interface SegmentReportingItems {
  [key: string]: SegmentReportingItem
}

export interface ParticipantsByMotionStateData {
  completed?: {
    total?: number
    percentageTotal?: string
  }
  active?: {
    total?: number
    percentageTotal?: string
  }
  entered?: {
    total?: number
    percentageTotal?: string
  }
}

// Motion Operational Statistics
export interface TenantInMotionReportingStatistics {
  FAILED?: number
  SUCCEEDED?: number
  users?: number
  accounts?: number
  // MAGNIFY_SEND_EMAIL Specific
  DELIVERED?: number
  OPEN?: number
  CLICK?: number
  BOUNCE?: number
  PROCESSED?: number
  FAKE_ACTION_EVENT_THAT_SHOULD_NOT_RENDER?: number
}

export interface TenantInMotionReportingActionReporting {
  statistics: TenantInMotionReportingStatistics
}

export interface TenantInMotionReportingAction {
  [actionName: string]: TenantInMotionReportingActionReporting
}

export interface TenantInMotionReporting {
  actions: TenantInMotionReportingAction[]
  executionId?: string
  journey: TenantInMotionReportingStatistics
}
